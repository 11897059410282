.UploadButton {
  cursor: pointer;
}
.UploadButton .ant-upload {
  width: 100%;
}
.UploadButton .UploadButton__content {
  border-radius: 6px;
  border: solid 2px rgba(39, 57, 65, 0.2);
  padding: 0 12px;
  margin-top: 30px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #147F74;
}
.UploadButton .UploadButton__content img,
.UploadButton .UploadButton__content svg {
  margin: 0 4px;
}
.UploadButton .UploadButton__content[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.UploadButton .UploadButton__content:hover {
  background-color: #1bab9c;
}
.UploadButton .UploadButton__content[disabled]:hover {
  background-color: #f5f5f5;
}
.UploadButton .UploadButton__content .UploadButton__upload-text {
  display: inline-block;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 3px 15px;
  color: white;
}
.UploadButton .UploadButton__content .UploadButton__upload-subtext {
  margin: 3px 15px;
}
